import i18n from "i18next";
import { initReactI18next } from "react-i18next";
 
import translationES from './es.json' ;

i18n
 .use(initReactI18next)
 .init({
   resources: {
     es: {
       translation: translationES
     }
   },
   lng: "es",
   defaultNS: 'translation',
   debug: false,
   interpolation: {
     escapeValue: false, 
   },
 });

 export default i18n;