import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import React, { Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n/i18n";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#8BC34A",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: [
      "Ubuntu Light",
      "Ubuntu Regular",
      "Ubuntu Bold",
      "sans-serif",
    ].join(","),
    caption: {
      fontFamily: "Ubuntu Light",
      fontSize: 8,
      color: "#263238",
    },
    body1: {
      fontFamily: "Ubuntu Light",
      fontSize: 14,
      color: "#263238",
    },
    body2: {
      fontFamily: "Ubuntu Light",
      fontSize: 12,
      color: "#263238",
    },
    button: {
      fontFamily: "Ubuntu Regular",
      fontSize: 16,
      color: "#263238",
    },
    h1: {
      marginBottom: 8,
      fontFamily: "Ubuntu Bold",
      fontSize: 32,
      color: "#263238",
    },
    h2: {
      fontFamily: "Ubuntu Bold",
      marginBottom: 8,
      fontSize: 28,
      color: "#263238",
    },
    h3: {
      fontFamily: "Ubuntu Bold",
      marginBottom: 8,
      fontSize: 24,
      color: "#263238",
    },
    h4: {
      fontFamily: "Ubuntu Regular",
      fontSize: 20,
      color: "#263238",
    },
    subtitle1: {
      color: "#263238",
      fontSize: 18,
      fontFamily: "Ubuntu Light",
      lineHeight: '21px',
      letterSpacing: 0.5
    },
    subtitle2: {
      color: "#263238",
      fontSize: 16,
      fontFamily: "Ubuntu Light",
      lineHeight: '21px',
      letterSpacing: 'normal'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: "inherit",
      },
      sizeLarge: {
        width: '217px'
      }
    },
  },
});

const Home = lazy(() => import("./routes/home/home"));

class Index extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("innoverso")}</title>
          <meta name="description" content={t("home_description")} />
          <link rel="canonical" href="https://innoverso.io/"></link>
        </Helmet>
        <ThemeProvider theme={theme}>
          <div className="iq__index">
            <Router>
              <Suspense fallback={<div>{t("loading")}</div>}>
                <Switch>
                  <Route exact path="/" component={Home} />
                </Switch>
              </Suspense>
            </Router>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

const IndexTranslate = withTranslation()(Index)

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<IndexTranslate/>, rootElement);
} else {
  ReactDOM.render(<IndexTranslate/>, rootElement);
}
